<template>
  <div class="orderList">
    <!-- <el-breadcrumb separator="/">
      <el-breadcrumb-item>邀请订单列表</el-breadcrumb-item>
    </el-breadcrumb> -->
    <div class="title">{{type=='consumption'?'订单列表':'邀请订单列表'}}</div>
    <hr />
    <div class="search">
      <div>
        <span>活动Id：</span>
        <el-input v-model="parmars.setId" placeholder="请输入活动Id"></el-input>
      </div>
      <div>
        <span>订单号：</span>
        <el-input
          v-model="parmars.coding"
          placeholder="请输入订单号"
        ></el-input>
      </div>
      <div>
        <span>店长名称：</span>
        <el-input
          v-model="parmars.shopName"
          placeholder="请输入店长名称"
        ></el-input>
      </div>
      <div>
        <span>店长手机号：</span>
        <el-input
          v-model="parmars.phone"
          placeholder="请输入店长手机号"
        ></el-input>
      </div>
      <div class="time" style="width: 28%">
        <span>游玩时间：</span>
        <el-date-picker
          v-model="playTime"
          type="datetimerange"
          align="right"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd HH:mm"
          format="yyyy-MM-dd HH:mm"
          style="width: 320px"
        ></el-date-picker>
      </div>
      <div>
        <el-button class="btn" type="primary" @click="getOrderList(1)">
          查询
        </el-button>
        <el-button class="btn" type="primary" @click="exportOrderList()">
          生成报表
        </el-button>
        <el-input style="opacity: 0" placeholder=""></el-input>
      </div>
    </div>
    <el-table :data="orderList" style="width: 100%" border>
      <el-table-column
        align="center"
        prop="setId"
        label="活动Id"
        width="width"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="setName"
        label="活动名称"
        width="250"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="orderType"
        label="订单类型"
        width="width"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="coding"
        label="订单号"
        width="width"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="beginTime"
        label="游玩时间"
        width="width"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="trueName"
        label="	店长名称"
        width="width"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="phone"
        label="	店长手机"
        width="width"
      ></el-table-column>
      <el-table-column align="center" label="操作" width="width">
        <template slot-scope="{ row }">
          <el-button
            type="primary"
            size="mini"
            @click="onOrderdetail(row.coding)"
          >
            查看详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="parmars.currentPage"
      :page-sizes="[5, 10, 15, 20]"
      :page-size="parmars.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pagination.total"
    ></el-pagination>
    <!-- <ul class="bottom_text">
      <li>总数:{{ totalNum }}</li>
      <li>总金额:{{ totalMoney }}</li>
      <li>总销售额:{{ totalSales }}</li>
    </ul> -->
  </div>
</template>

<script>
import {
  getInviteOrderList,
  exportInviteOrderList,
  getInviteOrderListConsumption,
  exportInviteOrderListConsumption,
} from "../../api/invite.js";
export default {
  data() {
    return {
      parmars: {
        currentPage: 1,
        pageSize: 10,
        setId: "",
        coding: "",
        shopName: "",
        phone: "",
        beginTime: "",
        endTime: "",
      },
      playTime: [],
      pagination: {},
      orderList: [],
      type: "",
    };
  },
  created() {
    this.type = this.$route.query.type;
    this.getOrderList();
  },
  watch: {
    $route(to, from) {
      this.type = this.$route.query.type;
      this.getOrderList();
    },
  },
  methods: {
    async getOrderList(page = "") {
      if (page) {
        this.parmars.currentPage = page;
      }
      if (this.playTime == null) {
        this.playTime = [];
      }
      this.parmars.beginTime = this.playTime[0] || "";
      this.parmars.endTime = this.playTime[1] || "";
      let demaad =
        this.type == "order"
          ? getInviteOrderList
          : getInviteOrderListConsumption;
      const { data } = await demaad(this.parmars);
      if (data.code == 0) {
        this.orderList = data.list;
        this.pagination = data.pagination;
      }
    },
    async exportOrderList() {
      // const loading = this.$loading({
      //   lock: true,
      //   text: "Loading",
      //   spinner: "el-icon-loading",
      //   background: "rgba(0, 0, 0, 0.7)",
      // });
      if (this.playTime == null) {
        this.playTime = [];
      }
      this.parmars.beginTime = this.playTime[0] || "";
      this.parmars.endTime = this.playTime[1] || "";
      let demaad =
        this.type == "order"
          ? exportInviteOrderList
          : exportInviteOrderListConsumption;
      const { data } = await demaad(this.parmars);
      let date = +new Date();
      let url = window.URL.createObjectURL(data); //转换文件流未url
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      if(this.type == "order"){
        link.setAttribute(
        "download",
        `万旅网小程序V2.0邀请管理-订单列表报表_${date}.xls`
      );
      }else{
        link.setAttribute(
        "download",
        `万旅网小程序V2.0消费管理-订单列表报表_${date}.xls`
      );
      }
      
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      // loading.close();
    },
    onOrderdetail(coding) {
      this.$router.push(`/refundOrderdetail/${coding}`);
    },
    handleSizeChange(num) {
      this.parmars.pageSize = num;
      this.getOrderList();
    },
    handleCurrentChange(num) {
      this.parmars.currentPage = num;
      this.getOrderList();
    },
  },
};
</script>

<style lang="less" scoped>
.orderList {
  .title {
    padding: 0 0 14px;
  }
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }
  .search {
    // margin-top: 20px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    div {
      margin-top: 10px;
      //   width: 25%;
      display: flex;
      align-items: baseline;
      margin-right: 10px;
      .el-date-editor {
        width: 300px;
      }
      .btn {
        margin-left: 20px;
      }
    }
    .el-select {
      width: 200px;
    }
    span {
      margin: 0 10px;
    }
    .el-input {
      width: 200px;
    }
  }
  .el-table {
    margin-top: 50px;
  }
  .el-pagination {
    margin-top: 50px;
    float: right;
  }
  .bottom_text {
    font-weight: bold;
    float: right;
    padding-top: 54px;
    padding-right: 10px;
    display: flex;
    li {
      margin: 0 10px;
    }
  }
}
</style>